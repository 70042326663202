$color1: #a0e0fa;
$color2: #0d1e68;
$color4: #313131;
$color5: #828282;
$background: #252525;
$white: #ffffff;
$black: #000000;

@mixin heading {
	position: relative;
	color: #ffffff;
	font-family: 'DM Serif Display', serif;
	font-size: 65px;
	letter-spacing: -2px;

	@media screen and (max-width: 768px) {
		font-size: 40px;
	}
}

@mixin hero-style {
	background-color: $background;
	color: $white;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	min-height: 100vh;
	padding: 150px 0;

	@media screen and (max-width: 768px) {
		padding: 100px 0;
	}
}
