@import '../../../stylesheets/Variables.scss';
$hero-tile: url('../../../assets/hero6.png');

.donate {
	@include hero-style;
	@media screen and (max-width: 768px) {
		height: 100%;
		background-position: 85%;
		background-attachment: fixed;
	}
	position: relative;
	background-image: $hero-tile;
	background-size: cover;

	&::before {
		content: '';
		position: absolute;
		top: 0px;
		right: 0px;
		bottom: 0px;
		left: 0px;
		background-color: rgba(0, 0, 0, 0.8);
	}
}

.donate-content {
	position: relative;
}

.donate-content-container {
	@media screen and (min-width: 1024px) {
		display: flex;
		gap: 15%;
	}
}

.donation-details {
	@media screen and (min-width: 1024px) {
		width: 60%;
	}
}

.contact-form {
	@media screen and (min-width: 1024px) {
		width: 40%;
	}
}

form {
	label {
		margin: 0.5rem 0;
		text-transform: upper;
	}
	input,
	textarea {
		width: 100%;
		display: block;
		background-color: transparent;
		border: 1.5px solid rgb(156, 156, 156);
		border-radius: 7px;
		padding: 0.8rem 0.5rem;
		color: #fff;
		&:focus {
			outline: none;
			border-color: #fff;
			transition: border 200ms ease-in;
		}
	}
	textarea {
		height: 250px;
	}

	.btn-container {
		display: flex;
		button {
			background-color: transparent;
			width: 150px;
			padding: 0.5rem;
			border-radius: 5px;
			border: 1.5px solid rgb(156, 156, 156);
			color: rgb(156, 156, 156);
			text-transform: capitalize;
			&:first-of-type {
				margin-right: 1rem;
			}
			&:hover {
				border-color: #fff;
				color: #fff;
				transition: all 200ms ease-in;
			}
		}
	}
}
