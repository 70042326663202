@import '../../../stylesheets/Variables.scss';

nav {
	display: flex;
	position: absolute;
	z-index: 100;
	width: 100%;
	align-items: center;
	justify-content: flex-end;
	flex-wrap: wrap;
	padding: 25px;
}

.nav-ul {
	display: none;
	align-items: center;
	justify-content: center;
	padding: 0;
	margin: 0;
	list-style-type: none;
	transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}

.nav-ul li a {
	display: inline-block;
	color: $white;
	padding: 10px 20px;
	text-decoration: none;
	letter-spacing: 3px;
	font-size: 14px;
	text-transform: uppercase;
}

.nav-ul li a:hover {
	color: $color1;
}

.hamburger div {
	height: 2px;
	background-color: $white;
	margin: 4px;
	border-radius: 100px;
	transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
}

.hamburger {
	padding: 10px;
}

.top-bar {
	width: 25px;
}

.middle-bar {
	width: 25px;
}

.bottom-bar {
	width: 25px;
}

.toggle .top-bar {
	background-color: #ffffff;
	transform: rotate(-45deg) translate(-2px, 4px);
}

.toggle .middle-bar {
	opacity: 0;
}

.toggle .bottom-bar {
	background-color: #ffffff;
	transform: rotate(45deg) translate(-5px, -6px);
}

@media screen and (max-width: 768px) {
	nav.active {
		background-color: rgba($color: #000000, $alpha: 0.95);
		transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
	}

	.nav-ul.active {
		display: flex;
		padding: 20px;
		flex-direction: column;
		width: 100%;
		height: 100vh;
		justify-content: flex-start;
		align-items: flex-start;
	}
}

@media screen and (min-width: 768px) {
	.hamburger {
		display: none;
	}

	.nav-ul {
		display: flex;
	}
}
