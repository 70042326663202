@import '../../../stylesheets/Variables.scss';

.projects {
	@include hero-style;
}

h1 {
	@include heading;
	font-size: 60px;
	padding-bottom: 20px;
}

.card {
	color: #000000;
	border: 1px solid $color5;
}

.card:hover {
	border: 1px solid $white;
}

a:hover {
	text-decoration: none;
}

.card-img-top {
	@media screen and (min-width: 768px) {
		width: 100%;
		height: 22vh;
		object-fit: cover;
	}
}

.card-body {
	color: $black;
}

.report {
	font-size: 20px;
	color: $color1;
	&:hover {
		color: $white;
	}
}
