@import '../../../stylesheets/Variables.scss';
$hero-tile: url('../../../assets/hero4.png');

.about-us {
	@include hero-style;
	height: 1100px;

	h1 {
		@include heading;
		font-size: 60px;
		padding-bottom: 20px;
	}
}

.feature-video {
	max-width: 850px;
}

.help-us {
	position: relative;
	display: flex;
	background-image: $hero-tile;
	background-size: cover;
	padding-top: 4rem;
	padding-bottom: 5rem;

	&::before {
		content: '';
		position: absolute;
		top: 0px;
		right: 0px;
		bottom: 0px;
		left: 0px;
		background-color: rgba(0, 46, 82, 0.8);
		backdrop-filter: grayscale(1);
	}
}

.help-us-content {
	position: relative;
}
