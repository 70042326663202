@import '../../../stylesheets/Variables.scss';

.footer {
	color: #fff;
	background-color: #131313;
	padding: 2em 1.5em;
	border-top: 1px solid #5f5f5f;
	display: flex;
	justify-content: space-around;
	align-items: center;
	@media screen and (max-width: 768px) {
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
	}
}

img {
	width: 85px;
	margin-bottom: 0.75em;
}

.links {
	a {
		text-decoration: none;
		color: #fff;
		font-size: 1.5em;
		margin-right: 0.5em;
		&:hover {
			color: $color1;
		}
	}
}

.site-details {
	@media screen and (max-width: 768px) {
		margin-bottom: 2em;
	}
}

.nav-footer {
	@media screen and (max-width: 768px) {
		margin-bottom: 2em;
	}
	ul {
		padding: 0;
		list-style: none;
		li {
			display: inline-block;
			&:not(:last-of-type) {
				margin-right: 1em;
			}
		}
		a {
			color: $white;
			&:hover {
				color: $color1;
			}
		}
	}
}
