@import '../../../stylesheets/Variables.scss';
$hero-tile: url('../../../assets/hero2.jpg');

.hero {
	position: absolute;
	height: 100vh;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background-image: $hero-tile;
	background-size: cover;

	@media screen and (max-width: 768px) {
		background-position: 45%;
	}

	&::before {
		content: '';
		position: absolute;
		top: 0px;
		right: 0px;
		bottom: 0px;
		left: 0px;
		background-color: rgba(0, 0, 0, 0.5);
	}
}

.heading {
	@include heading;
	padding-bottom: 20px;
}

.bio {
	margin: 0 200px 20px 200px;

	@media screen and (max-width: 768px) {
		margin: 0 20px 20px 20px;
	}
}

.hero p {
	position: relative;
	color: #ffffff;
}

.logo {
	width: 200px;
	position: relative;

	@media screen and (max-width: 768px) {
		width: 150px;
	}
}

.donation {
	position: relative;
	color: #ffffff;
	background-color: transparent;
	border-radius: 40px;
	padding: 10px 40px;
	margin-top: 10px;
	border: 1px solid #ffffff;
	text-transform: uppercase;
	letter-spacing: 3px;
	font-size: 14px;
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;

	&:hover {
		color: #000000;
		background-color: #ffffff;
		text-decoration: none;
	}
}