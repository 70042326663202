@import './Variables.scss';

body {
	font-family: 'IBM Plex Sans', sans-serif;
	font-size: 16px;
	background-color: $background;

	@media screen and (max-width: 768px) {
		font-size: 16px;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: 'DM Serif Display', serif;
	color: #ffffff;
}
