@import '../../../stylesheets/Variables.scss';

.project {
	@include hero-style;
	height: auto;
}

.heading {
	margin-right: 100px;
}

.feature-video {
	overflow: visible;
}

.gallery {
	margin-top: 1.5em;
	display: flex;
	flex-direction: column;

	img {
		width: 100%;
		height: 200px;
		object-fit: cover;
		border: 1px solid rgb(134, 134, 134);
		margin-bottom: 0.75em;
	}

	@media screen and (min-width: 768px) {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 0.75em;
		img {
			width: 250px;
			height: 200px;
			object-fit: cover;
			border: 1px solid rgb(134, 134, 134);
		}
	}
}
